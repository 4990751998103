<template>
  <div>
    <!-- 数据展示 -->
    <a-table
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :pagination="false"
      :bordered="tableBordered"
    >
      <span slot="createTime" slot-scope="text, record">
        {{ parseTime(record.createTime) }}
      </span>
      <span slot="userExplain" slot-scope="text, record">
        <div v-if="record.userExplainList">
          <span v-for="item in record.userExplainList">{{item.content}}</span>
        </div>
      </span>
      <span slot="operation" slot-scope="text, record">
        <a-divider type="vertical" v-hasPermi="['user:coverLetter:edit']" />
        <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:coverLetter:edit']">
          <a-icon type="edit" />修改
        </a>
        <a-divider type="vertical" v-hasPermi="['user:coverLetter:remove']" />
        <a @click="handleDelete(record)" v-hasPermi="['user:coverLetter:remove']"> <a-icon type="delete" />删除 </a>
      </span>
    </a-table>
  </div>
</template>

<script>
import { pageCoverLetter,listCoverLetter, delCoverLetter } from '@/api/user/coverLetter'
// import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'CoverLetter',
  components: {
    // CreateForm
  },
  props: {
    applyId: {
      type: String,
      required: true
    },
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        coverLetterId: null,
        userId: null,
        applyId:null,
        profileId: null,
        title: null,
        choose: null,
        needExplain: null,
        userExplain: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '选择',
          dataIndex: 'choose',
          ellipsis: true,
          align: 'center',
          customRender(f){
            if(f == 0){
              return '否'
            }else{
              return '是'
            }
          }
        },
        {
          title: '是否需要解释',
          dataIndex: 'needExplain',
          ellipsis: true,
          align: 'center',
          customRender(f){
            if(f == 0){
              return '否'
            }else{
              return '是'
            }
          }
        },
        {
          title: '解释内容',
          dataIndex: 'userExplainList',
          ellipsis: true,
          scopedSlots: { customRender: 'userExplain' },
          align: 'center'
        },
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户cover letter列表 */
    getList () {
      this.loading = true
      this.queryParam.applyId = this.applyId
      listCoverLetter(this.queryParam).then(response => {
        this.list = response.data
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        coverLetterId: undefined,
        userId: undefined,
        profileId: undefined,
        title: undefined,
        choose: undefined,
        needExplain: undefined,
        userExplain: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delCoverLetter(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/coverLetter/export', {
            ...that.queryParam
          }, `用户cover letter_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style scoped lang="less">


</style>
