import request from '@/utils/request'


// 查询用户cover letter列表
export function listCoverLetter(query) {
  return request({
    url: '/user/coverLetter/list',
    method: 'get',
    params: query
  })
}

// 查询用户cover letter分页
export function pageCoverLetter(query) {
  return request({
    url: '/user/coverLetter/page',
    method: 'get',
    params: query
  })
}

// 查询用户cover letter详细
export function getCoverLetter(data) {
  return request({
    url: '/user/coverLetter/detail',
    method: 'get',
    params: data
  })
}

// 新增用户cover letter
export function addCoverLetter(data) {
  return request({
    url: '/user/coverLetter/add',
    method: 'post',
    data: data
  })
}

// 修改用户cover letter
export function updateCoverLetter(data) {
  return request({
    url: '/user/coverLetter/edit',
    method: 'post',
    data: data
  })
}

// 删除用户cover letter
export function delCoverLetter(data) {
  return request({
    url: '/user/coverLetter/delete',
    method: 'post',
    data: data
  })
}
